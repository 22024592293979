/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6a856691-08a4-44d5-b4a5-2ccfc9ddb29b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_rfGRaRkqC",
    "aws_user_pools_web_client_id": "383modpr88j0ojr8hi1uf7qh18",
    "oauth": {},
    "XR": {
        "scenes": {
            "labtrainingpoc": {
                "sceneConfig": {
                    "sceneId": "46ab5ac8eb2f4fc4b3e47908efe9f257.scene",
                    "region": "us-east-2",
                    "projectName": "Lab Training",
                    "url": "https://sumerian.us-east-2.amazonaws.com/20180801/projects/Lab%20Training/release/authTokens?sceneId=46ab5ac8eb2f4fc4b3e47908efe9f257.scene"
                }
            },
            "labtraining": {
                "sceneConfig": {
                    "sceneId": "46ab5ac8eb2f4fc4b3e47908efe9f257.scene",
                    "region": "us-east-2",
                    "projectName": "Lab Training",
                    "url": "https://sumerian.us-east-2.amazonaws.com/20180801/projects/Lab%20Training/release/authTokens?sceneId=46ab5ac8eb2f4fc4b3e47908efe9f257.scene"
                }
            }
        }
    },
    "aws_content_delivery_bucket": "labtrainingpoc-20210106012415-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3g4uhov1piodm.cloudfront.net"
};


export default awsmobile;
