import React, {Component} from 'react';
import './App.css';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {SumerianScene} from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import Aws_exports from './aws-exports';
import '@aws-amplify/ui/dist/style.css';
import logo from './logo.png'

Amplify.configure(Aws_exports);

const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
        <div className="App" style={{
          display: 'flex',
          'flex-direction': 'column',
          height: '100vh',
          width: '100vw'
        }}>
          <div style={{
            display: 'flex',
            'flex-direction': 'row',
            justifyContent: 'bottom',
            alignItems: 'center',
            'margin-top': 'auto'
           }}>
              <img src={logo} height="111" width="165" />
              Hello, {user.username} <AmplifySignOut />
          </div>  
          <SumerianScene sceneName='labtraining'/>
        </div>
    ) : (
      <AmplifyAuthenticator>
        <div className="App" slot="sign-in" style={{
          display: 'flex',
          'flex-direction': 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src={logo} height="333" width="500" />
          <AmplifySignIn hideSignUp />
        </div>
      </AmplifyAuthenticator>
  );
}

export default AuthStateApp;